/* .calendly-container {
  margin: 100px auto;
  width: 90%;
  height: 20vh;
 
  background: #eaf1f5;
  background-position: center;
  background-size: cover;

  text-align: center;
  padding: 100px;
  border-radius: 15px;
  margin-bottom: 0;
}
.calendly-heading{
  
  font-size: 2rem;
  margin-bottom: px;
}
@media screen and (max-width: 850px){
  .hero-btn-2{
      font-size: .8rem;
      align-items: center;
    
  }
} */

.calendly-container {
  margin: 100px auto;
  width: 90%;
  height: 20vh;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../images/grd1.jpg); */
  background: #a6d6f1;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 100px;
  border-radius: 15px;
  margin-bottom: 0;

  /* Add flexbox styles to center content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calendly-heading {
  /* Adjust heading styles as needed */
  font-size: 2rem;
  margin-bottom: 20px;
  color: #0070ad;
}

@media screen and (max-width: 850px) {
  .hero-btn-2 {
    font-size: 0.8rem;
    align-items: center;
  }
  .calendly-heading{
    font-size: 1rem;
  }
}

.hero {
  width: 100%;
  height: 100%;
  position: relative;
}
.hero img {
  height: 80vh;
  width: 100%;
  object-fit: cover;
  opacity: 0.8;
}
.hero video {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  opacity: 1;
  
}
.home-H1 h1 {
  font-size: 2.5rem;
  color: #0070ad;
  margin-top: 3rem;
}
/* LIne no. 24 to 30 alignt item in ceter of home banner */
/* .home-H1 {
  position:absolute;
  top: 60%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
 text-align: start;
} */
.home-H1 {
  /* background: black; */
  font-weight: 600;
  font-size: 2rem;
  align-items: center;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  font-family: serif;
  margin: 1rem;
}
.hero-service {
  width: 100%;
  height: 100%;
  position: relative;
}
.hero-service video {
  height: 80vh;
  width: 100%;
  object-fit: cover;
}

.services-H1 h1 {
  background: rgb(20, 20, 20);
  font-weight: 800;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4rem;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.hero-contact {
  width: 100%;
  height: 100%;
  position: relative;
}
.hero-contact img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  opacity: 0.6;
}
.hero-contact video {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.hero-career video {
  height: 80vh;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 850px) {
  .home-H1 h1 {
    padding: 10px 20px;
    font-size: 1.2rem;

  }

  .hero video {
    width: 100%;
    height: 70vh;
  }
  .hero-service video {
    width: 100%;
    height: 60vh;
  }
  .hero-contact video {
    width: 100%;
    height: 70vh;
  }
  .hero-career video {
    width: 100%;
    height: 70vh;
  }
}

.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
margin-top: -3rem;
}
.contact-banner img{
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.contact-h1{
  position:absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
 text-align: center;
}
.contact-h1 h1{
  font-size: 4rem;
  color: white;
}
.contact-heading h2{
  font-size: 2rem;
  margin-top: 3rem;
  color :#0070ad;
}
.address-section {
  text-align: center;
  margin-bottom: 6rem;
  margin-right: 3rem;
  width: auto;
}
.office-location{
  margin-top: -100px;
}
.address-section address,
.address-section h2 {
  font-size: 1.2rem;
}

.office-location address, .office-location h2{
  font-size: 1.2rem;
}
.office-location h2{
  margin-right: 50px;
}
.contact-form-section {
  margin-left: 2rem;
  margin-bottom: 6rem;
}

.contact-form-section h1 {
  text-align: center;
}

.contact-form-section form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  height: auto;
  padding: 2rem;
  gap: 20px;
  box-shadow: 5px 5px 10px 10px rgb( 0, 0, 0, 0.1);
  border-radius: 5px;
background-color: white;


}

.contact-form-section input,
.contact-form-section textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.contact-form-section button {
  align-self: center;
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.contact-form-section button:hover {
  background-color: #0056b3;
  transition: 0.5s ease-in-out;
}
/* @media only screen and (max-width: 850px) {
  .contact-page {
    flex-direction: column-reverse;
    margin-top: -5rem;
  }

  .address-section {
    margin-right: 0;
  }

  .contact-form-section {
    margin: 2rem;
  }

  .contact-form-section form {
    width: 350px;
  }
  .contact-h1 h1{
    font-size: 2rem;
    color: white;
  }
  .contact-heading h2{
    font-size: 1.3rem;
    margin-top: 2rem;
    color :#0070ad;
  }
}
@media only screen and (max-width: 768px){

} */

/* Styles for screens with max width of 768px */
@media only screen and (max-width: 768px) {
  .contact-page {
    flex-direction: column-reverse;
  
  }

  .contact-banner img {
    height: 30vh;
  }

  .contact-h1 h1 {
    font-size: 2rem;
  }

  .contact-heading h2 {
    font-size: 1.3rem;
    margin-top: 2rem;
  }

  .address-section {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .address-section address,
  .address-section h2 {
    font-size: 1rem;
  }

  .contact-form-section {
    margin: 2rem;
  }

  .contact-form-section form {
    width: 100%;
    max-width: 350px;
    padding: 1rem;
  }
  .contact-form-section {
    margin: 2rem;
    padding: 2rem; /* Add padding instead of negative margin */
  }
}

/* Styles for screens with max width of 850px */
@media only screen and (max-width: 850px) {
  .contact-form-section form {
    width: 100%;
    max-width: 500px;
  }
}

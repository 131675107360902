/* .navbar {

  background: transparent;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position:fixed;
  top: 0;
  z-index: 20;
  width: 100%;
}
.navbar:hover{
  background-color:rgba(12, 12, 12, 0.827);
  transition: 1s ease-in-out;
}
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

 .nav-logo {
  align-items: center;

  cursor: pointer;
  text-decoration: none;
  flex-grow: 1;
} 
.nav-logo img{
  width: 10rem;
  height: auto;

}
.nav-menu {
   display: flex;
  list-style: none; 
  text-align: center;
  margin-right: 1rem; 

}
.navlink li{
  display: inline-block;
  padding: 6px 12px;
  position: relative;
  font-family: 'Courier New', Courier, monospace;
}
.nav-links  {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;


padding: 0.5rem 1.5rem;
}


.nav-item  {
  line-height: 25px;
  margin-right: 1.5rem;
 
}

.nav-item::after {
 
  content: '';
  width: 0;
  height: 2.5px;
  background: black;
  display:block;
  margin: auto;
  transition: 0.5s ease-in-out;
}

.nav-item:hover:after {
  width: 60%;
  background: #0070ad;
}

.nav-item .active {
 font-weight: 800;
 font-size: 1.5rem ;
}
.navbar.fixed{
  background-color: white;
  transition: 0.3s ease-in-out;
  height: 6rem;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 1020px) {
 

.nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%; 
    border-top: 1pxsolid #fff;
    background: transparent;
    height: auto;
    position: absolute;
    top: 0;
    left: -250%;
    opacity: 1;
    align-items:center;
    padding: 90px 0 30px 0;
    transition: all 0.5s ease; 
margin-top: 6rem;
 
  }
  .nav-links {
    padding: 2rem 0;
    width: 80%;
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    color: black;
  }
   .nav-menu.active {
    background-color:  white;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    color: black;
 
   
   
  } 
 
    .nav-item .active {
    color: black;
    font-weight: 700;
    font-size: 2rem;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display:block;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 2rem;
    cursor: pointer;
    color: #0070ad;
  } 
}  */
.navbar {
  background: transparent;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
}

.navbar:hover {
  background-color: rgba(12, 12, 12, 0.827);
  transition: 1s ease-in-out;
}

.nav-container {
  display: flex;
  justify-content: space-between; /* Adjusted for better alignment */
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  padding: 0 1rem;
}

.nav-logo {
  cursor: pointer;
  text-decoration: none;
  flex-grow: 1;
}

.nav-logo img {
  width: 10rem;
  height: auto;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 1rem;
}

.nav-links {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
  padding: 0.5rem 1.5rem;
}

.nav-item {
  line-height: 25px;
  margin-right: 1.5rem;
}

.nav-item::after {
  content: '';
  width: 0;
  height: 2.5px;
  background: black;
  display: block;
  margin: auto;
  transition: 0.5s ease-in-out;
}

.nav-item:hover:after {
  width: 60%;
  background: #0070ad;
}

.nav-item .active {
  font-weight: 800;
  font-size: 1.5rem;
}

.navbar.fixed {
  background-color: white;
  transition: 0.3s ease-in-out;
  height: 6rem;
}

.nav-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: #0070ad;
}

@media screen and (max-width: 1020px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background: white;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 1;
    align-items: center;
    padding: 90px 0 30px 0;
    transition: all 0.5s ease;
    margin-top: 6rem;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    padding: 2rem 0;
    width: 80%;
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    color: black;
  }

  .nav-item .active {
    color: black;
    font-weight: 700;
    font-size: 2rem;
    border: none;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 2rem;
    cursor: pointer;
    color: #0070ad;
  }
}

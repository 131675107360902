.course {
  width: 95%;
  margin: auto;
  padding-top: 100px;
  text-align: center;
}

.section-header {
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.section-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.section-header p {
  color: #5a5a5a;
  font-size: 1.2rem;
  font-weight: 500;
}

.row {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

/* Your existing styles for the course-col */
.course-col {
  flex-basis: 24%;
  background: #eaf1f5;
  border-radius: 20px;
  margin-bottom: 5%;
  padding: 20px 12px;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
}

.course-col h3 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  margin: 10px 0px;
  color: #0070ad;
}

.course-col p {
  font-size: 1.18rem;
  font-weight: 400;
  text-align: start;
  line-height: 1.5;
}

.course-col:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 850px) {
  @media screen and (max-width: 768px) {
    .course {
      padding-top: 50px;
    }
  
    .row {
      justify-content: center;
      flex-direction: column;
    }
  
    .course-col {
      flex-basis: 45%;
    }
  }
}

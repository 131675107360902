 /* logo color code:#0070ad; */

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@300;400;600;700;900&display=swap');
*{
  margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Source Serif Pro', serif;

}

h1{
  text-align: center;

}
p{
  text-align: center;
}
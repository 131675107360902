.whatsapp-container {
    position: fixed;
    bottom: 1rem;
    right:6rem;
    /* z-index: 999; */
  }
  
  .whatsapp-icon {
    width: 3rem;
    height: 3rem;
    color: green;
   background-color: white;
   border-radius: 8px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .whatsapp-icon:hover{
    transform: scale(1.1);
  }
  
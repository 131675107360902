
 .carrer {
  margin: 100px auto;
  width: 90%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../images/woman-left.jpg);
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 100px;
  border-radius: 15px;
  margin-bottom: 0;
}

.carrer {
  color: rgb(22, 13, 13);
  padding: 10px;
}

.carrer h1 {
  font-size: 3rem;
  font-weight: 600;
  text-align: start;
  align-items: center;
  margin: 4rem 5rem 0rem 2rem;
  color: white;
}

.carrer p {
  text-align: start;
  font-size: 1.25rem;
  margin: 0rem 2rem;
  color: white;
}

.cta {
  margin: 100px auto;
  width: 100%;
  height: 50vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(../images/conimgGrd6.jpg);
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 100px;
  margin-bottom: 0;
  color: white;
  padding: 10px;
}



.cta h1 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  align-items: center;
  margin: 4rem;
  color: white;

}

.hero-btn {
  display: inline-block;
  text-decoration: none;
  color: white;
  border: 3px solid black;
  padding: 12px 34px;
  font-size: 20px;
  background: #0070ad;
  position: relative;
  cursor: pointer;
  margin-right: 63rem; /* Remove the fixed margin-right value */
  margin-left: auto; /* Add margin-left:auto to center the button */
  margin-top: 1rem;
  transition: 0.5s ease-in-out;
}

.hero-btn:hover {
  border: 2px solid black;
  background: #0076b6c7;
  color: white;
  transition: 0.5s ease-in-out;
}

.cta .button-container {

  margin-top: 1rem;
}

.hero-btn-2 {
  display: inline-block;
  text-decoration: none;
  color: white;
  border: 3px solid black;
  padding: 12px 34px;
  font-size: 20px;
  background: #0070ad;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.hero-btn-2:hover {
  border: 2px solid black;
  background: #0076b6c7;
  color: white;
  transition: 0.5s ease-in-out;
}

  @media screen and (max-width: 850px) {
    .carrer {
      width: 100%;
      padding: 50px;
      height: 70vh;
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../images/happy-woman.jpg);
      background-position: center bottom; /* Add this line to place the background image at the bottom */
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: flex-end; /* Add this line to align content at the bottom */
    }
  
    .carrer h1 {
      margin: 1rem 1rem 0.5rem 1rem;
      color: black;
      font-size: 1.5rem;
      text-align: center;
    }
  
    .carrer p {
      margin: 0.5rem 1rem;
      font-weight: 600;
      color: black;
      font-size: 1.1rem;
      text-align: center;
    }
  
    .cta {
      width: 100%;
      padding: 50px;
      height: 40vh;
      
      }
      
      .cta h1 {
      margin: 2rem 1rem;
      font-size: 1rem;
      text-align: center;
      }
      
      .hero-btn {
        margin-right: auto; 
        margin-left: auto; 
        margin-top: 1rem;
      }
      
      .hero-btn-2 {
        margin-right: auto; 
        margin-left: auto;
        margin-top: 1rem;
      }
  }
 /* .trip {
  margin: 3rem 4rem;
  color: #2a2a2a;
}

.tripcard {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}
.t-card {
  width: 33%;
  text-align: center;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
 
  padding: 1rem 0.8rem;
}
.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
  text-align: center;
  color: #0070ad;
}
.t-card p{
  text-align: start;
}
.trip h2{
  font-size: 2.5rem;
  text-align:center;

}
.t-image {
  height: 300px;
  overflow: hidden;
  border-radius: 7px;
}
.t-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}
.t-image:hover img {
  transform: scale(1.3);
}
.read-more-button,
.read-less-button {
  background-color: #0070ad;
  color: #ffffff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  
}

.read-more-button:hover,
.read-less-button:hover {
  transform: scale(1.1);
  background-color: #0070ad;

}
@media screen and (max-width: 850px) {
  .trip {
    margin: 5rem 2rem;
  }

  .tripcard {
 
   flex-direction:column;
  }

  .t-card {
    width: 100%;
    height: 100%;
  }
  .read-more-button,
  .read-less-button {
    padding: 6px 12px;
    font-size: 12px;
  }
} 
   */

   /* TripData component */
   .trip {
    margin: 3rem 4rem;
    color: #2a2a2a;
  }
  
  .tripcard {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .t-card {
    width: calc(33.33% - 2rem);
    text-align: center;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    padding: 1rem 0.8rem;
    margin-bottom: 2rem;
  }
  
  @media screen and (max-width: 1024px) {
    .t-card {
      width: calc(50% - 2rem);
    }
  }
  
  @media screen and (max-width: 850px) {
    .trip {
      margin: 5rem 2rem;
    }
  
    .tripcard {
      flex-direction: column;
      align-items: center;
    }
  
    .t-card {
      width: 100%;
    }
  }
  
.footer {
    padding: 4rem, 6rem;
    background: rgb(17, 9, 9);
    color:white;
    width: 100%;   
    height: 12rem;
 
}


.footer-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.footer-data img {
  width: 9rem;

}
.footer-data h1:hover{
    color: rgb(8, 176, 243);

}

 .icon {
    color: white;
    font-size: 2rem;
    margin-right: 1rem;
}
.icon:hover{
    color: rgb(8, 176, 243);

}


@media screen and (max-width: 850px){
    
    .footer{
    width: 100%;
    height: 12rem;

   }
   .footer-data h1 {
    margin: 1rem 1.5rem;
}
.icon{
        color: white; 
  
}
.icon:hover{
    color: #0070ad;
}
 
}


  
  .trip {
  margin: 3rem 4rem;
  color: #0f0d0d;
}

.trip h1 {
  font-size: 4rem;
}



.t-card {
  width: 33%;

  text-align: start;
  /* box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);  */
  border-radius: 20px;
   cursor: pointer;
  padding: 1rem 0.8rem;

  color: black;
}
.t-card h4 {
  font-size: 1.3rem;
  padding: 0.9rem 0 0.2rem 0;
  text-align: center;
}

.t-card p {
  font-size: 1.1rem;
  line-height: 21.4px;

}
.t-card:hover {
  /* box-shadow: 0 0 90px 0 rgba(0, 0, 0, 0.5); */
  /* font-size: 2rem;
background-color:white;
color: black; */
}


.t-image {
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
}
.t-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}
.t-image:hover img {
  transform: scale(1.1);
}
.read-more-button, .read-less-button{
  cursor: pointer;
  color: blue;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: inline;
  font-size: 1.3rem
}
.read-more-button:hover,
.read-less-button:hover {
 
  cursor: pointer;
  color: blue;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  display: inline;
  transform:scale(1.1)
}
@media screen and (max-width: 850px) {
  .trip {
    margin: 4rem 2rem;
  }

  .tripcard {
    flex-direction: column;
  }

  .t-card {
    width: 100%;
    height: 100%;
    margin-bottom: 1.5rem;
  }
} 



.career-page {
  margin: 4rem 6rem;
  color: black;
}

.career-page h1 {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  color: #0070ad;
}

.job-listings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.job-card {
 background-color:  #eaf1f5;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.job-card:hover{
  box-shadow: 0 0 90px 0 rgba(0, 0, 0, 0.5);
}
h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}

h3 {
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  margin-bottom: 1rem;
  text-align: center;

}

p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

button {
  display: block;
  margin: 0 auto;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: blue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #003399;
}

@media screen and (max-width: 768px) {
  .career-page {
    margin: 2rem;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .job-listings {
    display: block;
  }

  .job-card {
    margin-bottom: 2rem;
  }

  button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
    margin: 0 auto;
    display: block;
  }
}
